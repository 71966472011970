import { Flex, Text } from '@chakra-ui/react'
import {
  BgreenLogo,
  BrusoftLogo,
  EstimuloLogo,
  EvoTalksLogo,
  GalapagosLogo,
  MundoLogisticaLogo,
  MutuaLogo,
  PositivoLogo,
  SBCLogo,
  SuperRicoLogo
} from '../Icon'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function GPTMaxClients({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'42px'}
      px={'20px'}
      align={'center'}
      as={'section'}
    >
      <Text
        fontSize={'xl'}
        as={'h1'}
        textAlign={'center'}
        color={colorsLight.primary.main}
      >
        Veja as empresas onde nossa{' '}
        <Text as={'strong'}>Inteligência Artificial</Text> é usada para acelerar
        os resultados
      </Text>
      <Flex
        w={'100%'}
        maxW={1440}
        flexWrap={'wrap'}
        justify={'center'}
        align={'center'}
        gap={'44px'}
      >
        <Flex>
          <BgreenLogo w={183.84} h={'100%'} />
        </Flex>
        <Flex>
          <PositivoLogo w={175.35} h={'100%'} />
        </Flex>
        <Flex>
          <GalapagosLogo w={243.34} h={'100%'} />
        </Flex>
        <Flex>
          <SuperRicoLogo w={184} h={'100%'} />
        </Flex>
        <Flex>
          <EstimuloLogo w={162} h={'100%'} />
        </Flex>
        <Flex>
          <MundoLogisticaLogo w={245.37} h={'100%'} />
        </Flex>
        <Flex>
          <MutuaLogo w={186.38} h={'100%'} />
        </Flex>
        <Flex>
          <BrusoftLogo w={196.69} h={'100%'} />
        </Flex>
        <Flex>
          <EvoTalksLogo w={186.38} h={'100%'} />
        </Flex>
        <Flex>
          <SBCLogo w={188} h={'100%'} />
        </Flex>
      </Flex>
    </Flex>
  )
}
