import { Flex, Link, Text, useColorMode } from '@chakra-ui/react'
import { GPTMaxLogo, UserCircle } from '../Icon'
import NextLink from 'next/link'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function Header({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: colorsLight.primary.main,
    color: colorsLight.primary.main,
    fontSize: '14px'
  }

  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      justify={'space-between'}
      py={'16px'}
      px={'20px'}
      as={'nav'}
      gap={'32px'}
      flexWrap={'wrap'}
      id="Header"
    >
      <Flex>
        <Text as={'h1'} srOnly>
          GPT Max
        </Text>
        <Link as={NextLink} href="/">
          <GPTMaxLogo w={['100px', '126px']} h={'100%'} />
        </Link>
      </Flex>

      <Flex align={'center'} gap={'16px'} flexWrap={'wrap'}>
        <Link
          as={NextLink}
          href="/login"
          style={buttonStyle}
          _hover={{
            textDecoration: 'none',
            bgColor: colorsLight.primary.main,
            'span, svg': {
              color: colorsLight.primary.onPrimary,
              stroke: colorsLight.primary.onPrimary
            }
          }}
        >
          <Flex align={'center'} gap={'10px'}>
            <UserCircle
              w={'26px'}
              h={'26px'}
              stroke={colorsLight.primary.main}
            />
            <Text as={'span'}>Acessar conta</Text>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}
