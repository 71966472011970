const { NEXT_PUBLIC_URL_SITE } = process.env

interface IMetaProps {
  image: string
  pageTitle: string
  siteName: string
  description: string
  keywords: string
  follow: boolean
  facebookPage?: string
  facebookAuthor?: string
  facebookAppId?: string
  twitterUser?: string
  canonical?: string
  locale?: string
}

export default function Meta({
  canonical = '/',
  follow = false,
  locale = 'pt_BR',
  facebookPage,
  facebookAuthor,
  facebookAppId,
  twitterUser,
  image,
  pageTitle,
  siteName,
  keywords,
  description
}: IMetaProps) {
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
      />
      <meta httpEquiv={'X-UA-Compatible'} content={'ie=edge'} />
      <link rel="canonical" href={`${NEXT_PUBLIC_URL_SITE}${canonical}`} />
      <meta
        name="robots"
        content={follow ? 'index, follow' : 'index, nofollow'}
      />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={`${pageTitle} | ${siteName}`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${NEXT_PUBLIC_URL_SITE}${canonical}`} />
      <meta property="og:image" content={`${image}`} />

      {facebookAppId && (
        <>
          <meta property="article:publisher" content={facebookPage} />
          <meta property="article:author" content={facebookAuthor} />
          <meta property="fb:app_id" content={facebookAppId} />
        </>
      )}

      {twitterUser && (
        <>
          <meta name="twitter:title" content={`${pageTitle} | ${siteName}`} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:url" content={`${NEXT_PUBLIC_URL_SITE}${canonical}`} />
          <meta name="twitter:image" content={`${image}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={NEXT_PUBLIC_URL_SITE} />
          <meta name="twitter:creator" content={twitterUser} />
          <meta name="twitter:domain" content={NEXT_PUBLIC_URL_SITE} />
        </>
      )}

      <meta itemProp="name" content={`${pageTitle} | ${siteName}`} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={`${image}`} />
      <meta itemProp={'url'} content={`${NEXT_PUBLIC_URL_SITE}${canonical}`} />
      <meta itemProp={'description'} content={description} />
    </>
  )
}
