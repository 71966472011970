import Accordion from '@/component/Accordion'
import { colorsLight } from '@/styles/colors'
import { Flex, Text } from '@chakra-ui/react'

interface Props {}

export default function Faq({}: Props) {
  return (
    <Flex w={'100%'} maxW={1440} flexDirection={'column'} align={'center'}>
      <Flex
        pt={['80px']}
        px={'20px'}
        flexDirection={'column'}
        align={['center']}
        gap={'64px'}
        w={'100%'}
        maxW={1216}
      >
        <Text
          fontSize={['xl', null, '3xl']}
          fontWeight={'semibold'}
          color={colorsLight.primary.main}
        >
          Perguntas Frequentes
        </Text>

        <Accordion />
      </Flex>
    </Flex>
  )
}
