import React from 'react'
import {
  Box,
  Container,
  Heading,
  Text,
  FormControl,
  Input,
  Textarea,
  Button,
  InputGroup,
  InputLeftElement,
  Checkbox,
  VStack,
  Image,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react'
import { colorsLight } from '@/styles/colors'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const cleanPhone = (phone: string) => phone.replace(/\D/g, '')

const contactFormSchema = z.object({
  email: z
    .string()
    .nonempty('E-mail é obrigatório')
    .email('Formato de e-mail inválido'),
  name: z
    .string()
    .nonempty('Nome é obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  cnpj: z
    .string()
    .nonempty('CNPJ é obrigatório')
    .length(14, 'CNPJ deve ter 14 dígitos')
    .regex(/^\d+$/, 'CNPJ deve conter apenas números'),
  phone: z
    .string()
    .transform(cleanPhone)
    .refine((val) => val.length >= 10 && val.length <= 11, {
      message: 'Telefone deve ter entre 10 e 11 dígitos'
    })
    .optional(),
  message: z.string().optional(),
  acceptTerms: z.boolean().refine((val) => val === true, {
    message: 'Você precisa aceitar os termos'
  })
})

type ContactFormData = z.infer<typeof contactFormSchema>

const formatPhone = (value: string) => {
  const numbers = value.replace(/\D/g, '')
  const length = numbers.length
  if (length === 0) return ''
  if (length < 3) {
    return `(${numbers}`
  }
  if (length < 4) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`
  }
  const isCellPhone = length > 10
  if (isCellPhone) {
    if (length < 11) {
      if (length === 9) {
        return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)} ${numbers.slice(
          3
        )}`
      }
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`
    }
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)} ${numbers.slice(
      3,
      7
    )}-${numbers.slice(7, 11)}`
  } else {
    if (length < 10) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`
    }
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 6)}-${numbers.slice(
      6,
      10
    )}`
  }
}

export function ContactForm() {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactFormSchema)
  })

  const inputStyles = {
    borderColor: colorsLight.primary.rgba20,
    color: colorsLight.primary.main,
    _placeholder: {
      color: colorsLight.primary.main,
      opacity: 0.7
    },
    _hover: {
      borderColor: colorsLight.primary.rgba20
    }
  }

  const checkboxStyles = {
    '.chakra-checkbox__control': {
      borderColor: colorsLight.primary.rgba20,
      _checked: {
        borderColor: colorsLight.primary.rgba20,
        bg: colorsLight.primary.onPrimary
        // borderColor: colorsLight.primary.main
      },
      _hover: {
        borderColor: colorsLight.primary.main
      }
    }
  }

  const onSubmit = async (data: ContactFormData) => {
    try {
      const formattedData = {
        ...data,
        phone: data.phone?.replace(/\D/g, '')
      }

      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formattedData)
      })

      const result = await response.json()

      if (!response.ok) {
        throw new Error(result.message || 'Erro ao enviar mensagem')
      }

      toast({
        title: 'Mensagem enviada com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      reset()
    } catch (error) {
      console.error('Error:', error)
      toast({
        title: 'Erro ao enviar',
        description:
          error instanceof Error
            ? error.message
            : 'Por favor, tente novamente mais tarde',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true
      })
    }
  }

  return (
    <Container maxW="container.md" py={10} id="Contact">
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading fontSize={'3xl'} color={colorsLight.primary.main} mb={4}>
            Ajudamos a sua empresa a acelerar a jornada
          </Heading>
          <Text color={colorsLight.primary.main} fontSize="lg">
            Nossos especialistas estão preparados para entender as necessidades
            da sua empresa e oferecer as melhores soluções para alavancar seu
            sucesso.
          </Text>
        </Box>

        <VStack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.email}>
            <Input
              placeholder="E-mail *"
              type="email"
              {...register('email')}
              {...inputStyles}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.name}>
            <Input
              placeholder="Nome *"
              type="text"
              {...register('name')}
              {...inputStyles}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.cnpj}>
            <Input
              placeholder="CNPJ *"
              type="text"
              maxLength={14}
              {...register('cnpj')}
              {...inputStyles}
            />
            <FormErrorMessage>
              {errors.cnpj && errors.cnpj.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.phone}>
            <InputGroup>
              <InputLeftElement>
                <Image
                  src="/brazil-flag.svg"
                  alt="Brazil flag"
                  boxSize="20px"
                />
              </InputLeftElement>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="(00) 00000-0000"
                    type="tel"
                    pl="40px"
                    value={formatPhone(field.value || '')}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value.length <= 15) {
                        field.onChange(value)
                      }
                    }}
                    {...inputStyles}
                  />
                )}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.message}>
            <Textarea
              placeholder="Mensagem"
              rows={4}
              {...register('message')}
              {...inputStyles}
            />
            <FormErrorMessage>
              {errors.message && errors.message.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            width="100%"
            bg={colorsLight.primary.main}
            color="white"
            size="lg"
            type="submit"
            isLoading={isSubmitting}
            _hover={{
              bg: colorsLight.primary.mainHover
            }}
          >
            Falar com a equipe
          </Button>

          <FormControl isInvalid={!!errors.acceptTerms}>
            <Checkbox
              {...register('acceptTerms')}
              sx={checkboxStyles}
              spacing="2"
            >
              <Text fontSize="sm" color={colorsLight.primary.main}>
                Ao se cadastrar, você confirma que concorda com o processamento
                de seus dados pessoais pela Abstrato.ai
              </Text>
            </Checkbox>
            <FormErrorMessage>
              {errors.acceptTerms && errors.acceptTerms.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      </VStack>
    </Container>
  )
}
function toast(arg0: {
  title: string
  status: string
  duration: number
  isClosable: boolean
}) {
  throw new Error('Function not implemented.')
}
