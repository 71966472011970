import React from 'react'
import { Box, Flex, keyframes } from '@chakra-ui/react'
import { colorsLight } from '@/styles/colors'

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

export function MarqueeBanner() {
  const items = [
    'Construa seu agente de IA',
    'Maximize sua produtividade',
    'Implemente inteligência artificial',
    'Personalize sua IA'
  ]

  const animation = `${scrollAnimation} 40s linear infinite`

  return (
    <Flex w="100%" h="100px" overflow="hidden" position="relative">
      <Flex
        w="100%"
        h="100%"
        bg={colorsLight.primary.main}
        align="center"
        justify="center"
        position="absolute"
        top="0"
        left="0"
        zIndex={0}
      />

      <Flex
        position="absolute"
        whiteSpace="nowrap"
        animation={animation}
        fontSize="3rem"
        fontWeight="bold"
        alignItems="center"
        h="100%"
        zIndex={1}
      >
        {[...items, ...items].map((item, index) => (
          <Flex
            key={`item-${index}`}
            px="4"
            mx="8"
            transition="color 0.3s"
            color={colorsLight.primary.onPrimary}
            align="center"
            opacity={0.15}
          >
            {item}
            <Box
              w="12px"
              h="12px"
              bgColor={colorsLight.primary.onPrimary}
              ml="56px"
              flexShrink={0}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
