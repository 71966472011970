import { AspectRatio } from '@chakra-ui/react'

interface Props {
  id: string | number
}

export default function VideoVimeo({ id }: Props) {
  return (
    <AspectRatio ratio={16 / 9}>
      <iframe
        src={`https://player.vimeo.com/video/${id}?autopause=0&loop=1&autoplay=1&muted=1&player_id=0&controls=0&app_id=58479`}
        allow="autoplay; fullscreen; picture-in-picture"
        title="GPT Max"
      />
    </AspectRatio>
    //@ts-ignore
    // <Vimeo
    //   responsive
    //   video={id}
    //   muted
    //   autoplay
    //   loop
    //   autopause={false}
    //   controls={false}
    //   playsInline
    // />
  )
}
