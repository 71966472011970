import { MarqueeBanner } from '@/component/Lp2024/MarqueeBanner'
import { colorsLight } from '@/styles/colors'
import { Flex, Link, Text, useColorMode } from '@chakra-ui/react'

interface Props {}

export default function Banner({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: colorsLight.primary.main
  }

  return (
    <>
      <Flex
        w={'100%'}
        position={'relative'}
        flexDirection={'column'}
        align={'center'}
        as={'aside'}
        bgImage={
          'url(https://d1k1f4n2h095ym.cloudfront.net/agent_blue_filter.png)'
        }
        bgSize={'cover'}
        bgPosition={'center'}
      >
        <Flex
          position={'absolute'}
          left={'-30%'}
          width={'1059px'}
          height={'1059px'}
          filter={'blur(45px)'}
          borderRadius={'1059px'}
          background={
            'radial-gradient(50% 50% at 50% 50%, rgba(134, 169, 210, 0.4) 0%, rgba(77, 249, 174, 0.00) 100%)'
          }
        />
        <Flex
          zIndex={2}
          px={'20px'}
          w={'100%'}
          maxW={1440}
          align={'center'}
          flexDirection={['column-reverse', null, null, 'row']}
          justify={'space-between'}
          gap={'32px'}
        >
          <Flex
            flexDir={'column'}
            gap={'24px'}
            py={['40px']}
            color={colorsLight.primary.main}
            _before={{
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                'linear-gradient(90deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 100%)',
              borderRadius: 'inherit',
              zIndex: 0
            }}
          >
            <Flex
              flexDirection="column"
              gap={'24px'}
              position="relative"
              zIndex={1}
            >
              <Text
                fontSize={'xx-large'}
                letterSpacing={'-1.1px'}
                maxW={[772]}
                // lineHeight={['36px', '58px']}
                as={'h1'}
                color={colorsLight.primary.main}
                lineHeight={1.1}
              >
                Crie uma{' '}
                <Text fontSize={'xxx-large'} as={'strong'}>
                  inteligência artificial personalizada
                </Text>{' '}
                para sua empresa
              </Text>
              <Text maxW={['100%', null, 508]} fontSize={'20px'}>
                Tenha sua própria base de dados e treine seu agente com os dados
                da sua empresa de um jeito fácil e totalmente customizado.
              </Text>

              <Text
                textTransform={'uppercase'}
                textStyle={'space'}
                fontWeight={'bold'}
                fontSize={'x-small'}
              >
                ENTRE EM CONTATO COM NOSSA EQUIPE E SAIBA COMO <br />
                PODEMOS TE AJUDAR A OBTER MAIS SUCESSO!
              </Text>
              <Flex align={'center'} flexWrap={'wrap'} gap={['12px', '36px']}>
                <Link
                  href="#Contact"
                  style={buttonStyle}
                  bgColor={colorsLight.primary.main}
                  color={colorsLight.primary.onPrimary}
                  _hover={{
                    textDecoration: 'none',
                    bgColor: 'transparent',
                    color: colorsLight.primary.main
                  }}
                  id="free-access-banner"
                >
                  Falar com a equipe
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <MarqueeBanner />
    </>
  )
}
