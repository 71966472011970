import { DarkMode, Flex } from '@chakra-ui/react'
import Meta from '@/component/Meta'
import Head from 'next/head'
import { GetServerSideProps } from 'next'
import { stripe } from '@/services/stripe'
import { StripeProductsProps } from '@/interfaces/Stripe'
import Script from 'next/script'
import Header from '@/component/Lp2024/Header'
import Banner from '@/component/Lp2023/Banner'
import Video from '@/component/Lp2024/Video'
import DemoChat from '@/component/Lp2023/DemoChat'
import SettingsTab from '@/component/Lp2023/SettingsTab'
import AIPartnerCompanies from '@/component/Lp2023/AIPartnerCompanies'
import CardBox from '@/component/Lp2023/CardBox'
import Language from '@/component/Lp2023/Language'
import TimeLapse from '@/component/Lp2023/TimeLapse'
import Faq from '@/component/Lp2023/Faq'
import Footer from '@/component/Footer'
import AboutUs from '@/component/Lp2023/AboutUs'
import WhatsAppLink from '@/component/Lp2023/WhatsAppLink/WhatsAppLink'
import WhatsAppLinkFaq from '@/component/Lp2023/WhatsAppLink/WhatsAppLinkFaq'
import WhatsAppLinkCompany from '@/component/Lp2023/WhatsAppLink/WhatsAppLinkCompany'
import { getSubdomain } from '@/services/subdomain'
import { api } from '@/services/api'
import { TenantsProps } from '@/interfaces/Tenants'
import GPTMaxClients from '@/component/Lp2024/GPTMaxClients'
import { ContactForm } from '@/component/Lp2024/ContactForm'

interface Props {
  plans: StripeProductsProps[]
}

export default function Lp({ plans }: Props) {
  return (
    <DarkMode>
      <script
        defer
        id="script-gptmax"
        src="https://d1nfa9z59crrh.cloudfront.net/scripts-files/index.umd.js?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTcxLCJrbm93bGVkZ2VfYmFzZV9pZCI6NDAwMDN9.TAFM47CEZdOLHmFA8XHg8qrScRAmafj8-a_iyWY-0rE"
      />

      <Head>
        <title>GPT Max | Crie sua própria inteligência artificial</title>
        <Meta
          image={`https://d1k1f4n2h095ym.cloudfront.net/filters:quality(42)/mockup.png`}
          pageTitle={'GPT Max | Crie sua própria inteligência artificial'}
          siteName={'GPT Max'}
          description={
            'Crie sua própria base de dados e treine sua inteligência artificial para  fornecer respostas às dúvidas de clientes e automatizar tarefas. Teste agora.'
          }
          keywords={
            'Conversação Personalizada, Chat GPT Max, Interação Inteligente, Recursos Avançados, Upload de Arquivos, Orientação por Textos, Controle Total, Aprendizado de IA.'
          }
          follow
          canonical={'/'}
        />
      </Head>
      <Flex flexDirection={'column'} position={'relative'}>
        {/* <HeaderBf /> */}
        <Flex
          flexDirection={'column'}
          align={'center'}
          w={'100%'}
          textStyle={'poppins'}
          overflow={'hidden'}
          position={'relative'}
        >
          <Header />
          <Banner />
          <Video />
          <GPTMaxClients />
          <DemoChat />
          <SettingsTab />
          <AIPartnerCompanies />
          <WhatsAppLink />
          <CardBox />
          <Language />
          <WhatsAppLinkFaq />
          <AboutUs />
          <TimeLapse />
          <WhatsAppLinkCompany />
          <ContactForm />
          <Faq />
          <Footer />
        </Flex>
      </Flex>
    </DarkMode>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const jwt = process.env.API_TOKEN
    const host = context.req.headers.host
    const subdomain = getSubdomain(host as string)

    // Verifica se existe um subdomínio
    if (subdomain) {
      // Busca os tenants válidos
      const { data } = await api.get<TenantsProps>(`/api/tenants/`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })

      const validSubdomains = data.items.map((item) => item.url)

      // Se for um subdomínio válido, redireciona para login
      if (validSubdomains.includes(subdomain)) {
        return {
          props: {},
          redirect: {
            destination: '/login',
            permanent: false
          }
        }
      }
    }

    // Se não houver subdomínio ou ele não for válido, busca os planos
    const { data } = await stripe.products.list({
      expand: ['data.default_price'],
      limit: 100,
      active: true
    })

    return {
      props: {
        plans: data.filter((p) => p.metadata?.slug)
      }
    }
  } catch (error) {
    console.error('Error in getServerSideProps:', error)

    return {
      props: {
        plans: []
      },
      redirect: {
        destination: '/error',
        permanent: false
      }
    }
  }
}

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   return {
//     props: {},
//     redirect: {
//       destination: '/login',
//       permanent: false
//     }
//   }
//   /* try {
//     const jwt = process.env.API_TOKEN
//     const host = context.req.headers.host
//     const subdomain = getSubdomain(host as string)

//     const { data } = await api.get<TenantsProps>(`/api/tenants/`, {
//       headers: {
//         Authorization: `Bearer ${jwt}`
//       }
//     })

//     const validSubdomains = data.items.map((item) => item.url)
//     if (validSubdomains.includes(subdomain)) {
//       return {
//         props: {},
//         redirect: {
//           destination: '/login',
//           permanent: false
//         }
//       }
//     }
//   } catch (error) {}
//   try {
//     const { data } = await stripe.products.list({
//       expand: ['data.default_price'],
//       limit: 100,
//       active: true
//     })

//     return {
//       props: {
//         plans: data.filter((p) => p.metadata?.slug)
//       }
//     }
//   } catch (e) {
//     // console.log(e)
//     return {
//       props: {
//         plans: []
//       },
//       redirect: {
//         destination: '/error',
//         permanent: false
//       }
//     }
//   } */
// }
