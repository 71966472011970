import { Flex, Text } from '@chakra-ui/react'
import {
  AwsLogo,
  ClickUpLogo,
  EvoTalksLogo,
  GoogleLogo,
  MicrosoftLogo,
  OpenAILogo
} from '../Icon'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function AIPartnerCompanies({}: Props) {
  return (
    <Flex
      flexDir={'column'}
      gap={'42px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      py={['60px', null, '120px']}
      color={colorsLight.primary.main}
    >
      <Text
        fontSize={'xl'}
        as={'h1'}
        textAlign={'center'}
        maxW={680}
        color={colorsLight.primary.main}
      >
        <Text as={'strong'}>Trabalhamos em parceria</Text> com renomadas
        empresas de todo o mundo para proporcionar a você o que há de melhor.
      </Text>
      <Flex
        w={'100%'}
        maxW={1440}
        flexWrap={'wrap'}
        justify={'center'}
        align={'center'}
        gap={'56px'}
      >
        <Flex>
          <OpenAILogo w={180} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
        <Flex>
          <GoogleLogo w={129} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
        <Flex>
          <MicrosoftLogo w={166} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
        <Flex>
          <AwsLogo w={90} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
        <Flex>
          <EvoTalksLogo w={207.55} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
        <Flex>
          <ClickUpLogo w={209.3} h={'100%'} fill={colorsLight.primary.main} />
        </Flex>
      </Flex>
    </Flex>
  )
}
