import { Flex, Link, Text } from '@chakra-ui/react'
import { AbstratoAiLogo } from '@/component/Icon'
import { colorsLight } from '@/styles/colors'
import { TfiYoutube, TfiLinkedin } from 'react-icons/tfi'
import { BiUpArrowAlt } from 'react-icons/bi'
import { MarqueeFooter } from '../Lp2024/MarqueeFooter'

interface Props {}

export default function Footer({}: Props) {
  return (
    <Flex
      justify={'center'}
      align={'center'}
      as={'section'}
      w={'100%'}
      // pb={'60px'}
      flexDirection={'column'}
      gap={'40px'}
    >
      <MarqueeFooter />

      <Flex w={'full'} bg={`${colorsLight.primary.main}20`} justify={'center'}>
        <Flex
          py={'60px'}
          w={'100%'}
          gap={'32px'}
          maxW={1216}
          justify={'space-between'}
          px={'20px'}
          flexWrap={'wrap'}
          color={colorsLight.primary.main}
        >
          <Flex flexDirection={'column'} maxW={520} gap={'24px'}>
            <Link href="https://abstrato.ai" isExternal>
              <Flex>
                <AbstratoAiLogo w={247} h={'100%'} />
              </Flex>
            </Link>
            <Text fontSize={'sm'}>
              <Text as={'b'}>
                Na Abstrato.AI, IA não é tendência, é o que move o mercado.
                <br />
              </Text>
              Somos especialistas em entregar o que empresas realmente precisam:
              solução inteligente, crescimento acelerado e experiências que
              brilham. Nossas soluções vão além do hype, são o futuro, hoje.
              Aqui, IA não é tendência, é o motor que faz o seu negócio crescer.
            </Text>
            <Text>Vem para quem entende</Text>
            <Text fontSize={'sm'}>
              55.901.865/0001-10 - Abstrato AI <br />
              {new Date().getFullYear()} © Todos os direitos reservados
            </Text>
          </Flex>

          <Flex
            flexDirection={'column'}
            gap={'16px'}
            color={colorsLight.primary.main}
          >
            <Text fontWeight={'bold'}>Institucional</Text>

            <Link href={'/cookies'} mt={'8px'} fontSize={'sm'}>
              Políticas de Cookies
            </Link>
            <Link href={'/terms'} mt={'8px'} fontSize={'sm'}>
              Termos e condições de uso
            </Link>

            <Link href={'/login'} mt={'8px'} fontSize={'sm'}>
              Acessar conta
            </Link>
          </Flex>

          <Flex gap={'16px'} flexDir={'column'}>
            <Flex justifyContent={'space-between'}>
              <TfiYoutube size={48} />
              <TfiLinkedin size={48} />
            </Flex>

            <Link
              href={'#Header'}
              mt={'8px'}
              color={colorsLight.primary.main}
              fontSize={'sm'}
              display={'flex'}
              alignItems={'center'}
            >
              Voltar ao topo
              <BiUpArrowAlt />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
