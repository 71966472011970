import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { BsWhatsapp } from 'react-icons/bs'
import WhatsAppButton from './WhatsAppButton'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function WhatsAppLinkFaq({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: colorsLight.primary.main
  }
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      py={'90px'}
      color={colorsLight.primary.main}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
        maxW={680}
        color={colorsLight.primary.main}
      >
        Está em dúvida?
      </Text>
      <Text fontSize={'xl'} textAlign={'center'}>
        Fale diretamente com nossos especialistas.
      </Text>
      <Flex align={'center'} flexWrap={'wrap'} gap={['12px', '36px']}>
        <Link
          href="#Contact"
          style={buttonStyle}
          bgColor={'transparent'}
          color={colorsLight.primary.main}
          _hover={{
            textDecoration: 'none',
            bgColor: colorsLight.primary.main,
            color: colorsLight.primary.onPrimary
          }}
          id="free-access-banner"
        >
          Falar com a equipe
        </Link>
      </Flex>
    </Flex>
  )
}
