export interface FaqProps {
  title: string
  content: string
  items?: string[]
}

export const Faq: FaqProps[] = [
  {
    title: 'O que é o GPT MAX?',
    content:
      'O GPT MAX é uma plataforma de inteligência artificial (IA) avançada que permite criar e personalizar avatares de IA. Com ele, você pode:Conversar com sua própria base de dados: Faça perguntas e obtenha respostas precisas da IA com base em suas informações.Criar avatares personalizados: Defina a personalidade, aparência e voz do seu avatar para uma experiência única.Publicar no marketplace: Compartilhe sua base de conhecimento com outros usuários e gere receita.Integrar com seus sistemas: Conecte o GPT MAX aos seus sistemas atuais via APIs.Automatizar tarefas: Libere tempo para tarefas estratégicas automatizando tarefas rotineiras.Publicar em redes sociais: Crie vídeos do seu avatar falando as respostas do chat e publique em suas redes sociais.'
  },
  {
    title: 'Quais vantagens em ser um assinante GPT MAX?',
    content:
      'As vantagens de ser um assinante GPT MAX são diversas: Personalização e autonomia: Controle total sobre a criação e personalização do avatar. Integração e automação: Fácil integração com sistemas empresariais através de APIs.Melhoria da produtividade: Aumento da eficiência das equipes ao automatizar tarefas.Engajamento nas redes sociais: Criação de conteúdo dinâmico e atraente com o avatar.Aprimoramento contínuo: Acesso a novas funcionalidades e atualizações.'
  },
  {
    title: 'Como posso personalizar meu avatar no GPT MAX?',
    content:
      'Você pode personalizar a personalidade e a aparência do seu avatar.Personalidade: Utilize o modelo NERIS® para definir o tipo de personalidade do avatar, como formal, amigável, etc.Aparência: Personalize a aparência do avatar, incluindo estilo, expressões faciais, vestuário e mais.'
  },
  {
    title: 'Como o GPT MAX interage com as redes sociais?',
    content:
      'O GPT MAX não vai publicar as respostas do chat em suas redes sociais. Mas você sim pode copiar o que foi criado e postar em suas redes, todo conteúdo criado dentro do GPT Max é de sua autoria. Ele também pode criar vídeos do seu avatar falando as respostas, proporcionando uma forma mais dinâmica e atraente de compartilhar informações.'
  },
  {
    title: 'Como o GPT MAX funciona com voz?',
    content:
      'O GPT MAX será capaz de entender e responder por voz, oferecendo uma experiência mais natural. Você também poderá clonar sua voz para que seu avatar fale como você'
  },
  {
    title: ' Quem pode usar o GPT MAX?',
    content:
      'O GPT MAX foi desenvolvido para empresas que tem a necessidade de gerenciar as ferramentas de IA usadas pelos seus times. Além disso, o GPT Max é seguro, isso quer dizer que suas bases de conhecimento estarão guardadas à 7 chaves. Ele é versátil e se adapta a diversas aplicações, desde conversas cotidianas até análises complexas de negócios.'
  }
]
