import { Flex, Link, Text } from '@chakra-ui/react'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function WhatsAppLinkCompany({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: colorsLight.primary.main
  }
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      pt={['80px', null, '120px']}
      pb={['40px', null, '30px']}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
        color={colorsLight.primary.main}
      >
        Quer descobrir como o GPT Max <br /> pode ser usado dentro da sua
        empresa?
      </Text>
      <Flex align={'center'} flexWrap={'wrap'} gap={['12px', '36px']}>
        <Link
          href="#Contact"
          style={buttonStyle}
          bgColor={'transparent'}
          color={colorsLight.primary.main}
          _hover={{
            textDecoration: 'none',
            bgColor: colorsLight.primary.main,
            color: colorsLight.primary.onPrimary
          }}
          id="free-access-banner"
        >
          Falar com a equipe
        </Link>
      </Flex>
    </Flex>
  )
}
