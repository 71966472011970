import React from 'react'
import { Box, Flex, keyframes } from '@chakra-ui/react'
import { colorsLight } from '@/styles/colors'

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

export function MarqueeFooter() {
  const items = [
    'Desenvolva seu assistente',
    'Otimize seu chat',
    'Transforme seus processos',
    'Eleve seus resultados'
  ]

  const animation = `${scrollAnimation} 40s linear infinite`

  return (
    <Box w="100%" h="200px" color="white" overflow="hidden" position="relative">
      <Flex
        position="absolute"
        whiteSpace="nowrap"
        animation={animation}
        fontSize="3rem"
        fontWeight="bold"
        alignItems="center"
        h="100%"
      >
        {items.map((item, index) => (
          <Flex
            key={`first-${index}`}
            px="4"
            mx="8"
            transition="color 0.3s"
            color={colorsLight.primary.main}
            alignItems={'center'}
          >
            {item}
            <Flex
              w={'12px'}
              h={'12px'}
              bgColor={colorsLight.primary.main}
              ml={'56px'}
              flexShrink={0}
            />
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}
