import { Flex, Link, Text } from '@chakra-ui/react'
import { colorsLight } from '@/styles/colors'

interface Props {}

export default function WhatsAppLink({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: colorsLight.primary.main
  }
  return (
    <Flex
      flexDir={'column'}
      gap={'12px'}
      px={'20px'}
      align={'center'}
      as={'section'}
      pb={['90px', null, '120px']}
      pt={['40px', 0]}
      color={colorsLight.primary.main}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        as={'h1'}
        textAlign={'center'}
        maxW={680}
        color={colorsLight.primary.main}
      >
        Veja como o <Text as={'strong'}>GPT Max</Text>
        <br /> pode ajudar o seu negócio hoje!
      </Text>
      <Text fontSize={'xl'} textAlign={'center'}>
        Toque no botão abaixo e converse diretamente com nosso time de
        especialistas.
      </Text>
      <Flex align={'center'} flexWrap={'wrap'} gap={['12px', '36px']}>
        <Link
          href="#Contact"
          style={buttonStyle}
          bgColor={colorsLight.primary.main}
          color={colorsLight.primary.onPrimary}
          _hover={{
            textDecoration: 'none',
            bgColor: 'transparent',
            color: colorsLight.primary.main
          }}
          id="free-access-banner"
        >
          Falar com a equipe
        </Link>
      </Flex>
    </Flex>
  )
}
