import { Avatar, Flex, Text } from '@chakra-ui/react'

interface Props {
  name: string
  title: string
  avatar: string
  description: string
}

export default function Card({ title, avatar, name, description }: Props) {
  return (
    <Flex
      padding={'32px'}
      rounded={'lg'}
      flexDir={'column'}
      gap={'24px'}
      bgColor={'whiteAlpha.900'}
      color={'grey.900'}
      flex={1}
      minW={296}
      //   maxW={420}
    >
      <Flex align={'center'} gap={'16px'}>
        <Avatar src={avatar} name={name} />
        <Flex flexDir={'column'}>
          <Text fontWeight={'bold'}>{name}</Text>
          <Text fontSize={'sm'}>{title}</Text>
        </Flex>
      </Flex>

      <Text as={'q'} fontSize={'sm'} color={'blackAlpha.700'}>
        {description}
      </Text>
    </Flex>
  )
}
